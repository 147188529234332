.nm-model-band {
	--car-width: 100%; /* calc(100vw / (Int(100vw / 230) + (2/3))); */
	--car-height: calc( var(--car-width) * .4296);
	padding: 0 0 var(--space-xxl);
	user-select: none;
}

.audi-model-band {
	display: block;
	max-width: 100%;
	overflow: hidden;
}

@media all and (min-width: 320px) and (max-width: 459px) {
	.nm-model-band {
		--car-width: calc(100vw / 1.666);
	}
}

@media all and (min-width: 460px) and (max-width: 689px) {
	.nm-model-band {
		--car-width: calc(100vw / 2.666);
	}
}

@media all and (min-width: 690px) and (max-width: 919px) {
	.nm-model-band {
		--car-width: calc(100vw / 3.666);
	}
}

@media all and (min-width: 920px) and (max-width: 1149px) {
	.nm-model-band {
		--car-width: calc(100vw / 4.666);
	}
}

@media all and (min-width: 1150px) and (max-width: 1379px) {
	.nm-model-band {
		--car-width: calc(100vw / 5.666);
	}
}

@media all and (min-width: 1380px) and (max-width: 1609px) {
	.nm-model-band {
		--car-width: calc(100vw / 6.666);
	}
}

@media all and (min-width: 1610px) and (max-width: 1839px) {
	.nm-model-band {
		--car-width: calc(100vw / 7.666);
	}
}

@media all and (min-width: 1840px) {
	.nm-model-band {
		--car-width: calc(100vw / 8.666);
	}
}

.nm-model-band input {
	display: none;
}

.nm-model-band .nm-model-band-headline {
	font-weight: var(--font-weight-bold);
	margin: 0 auto var(--space-m);
	width: calc(100% - (2 * var(--space-column)));
}

.nm-model-band .nm-model-band-tab-navigation {
	margin: 0 auto var(--space-xl);
	width: calc(100% - (2 * var(--space-column)));
}

.nm-model-band .nm-model-band-tab-navigation li {
	display: inline-block;
	margin-left: var(--space-xl);
}

.nm-model-band .nm-model-band-tab-navigation li:first-child {
	margin-left: 0 !important;
}

.nm-model-band .nm-model-band-stripe {
	height: 100%;
	position: relative;
}

.nm-model-band .nm-model-band-stripe-container {
	position: relative;
	width: 100%;
}

.nm-model-band .nm-model-band-stripe-container::after {
	background: #fff;
	bottom: 0;
	content: "";
	display: block;
	height: 25px;
	left: 0;
	position: absolute;
	width: 100%;
}

.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe-wrap {
	display: none;
}

.nm-model-band .nm-model-band-stripe-container.nm-model-band-stripe-container-hidden .nm-model-band-stripe-wrap {
	display: none !important;
}

.nm-model-band .nm-model-band-tab-nav-models-input:checked ~ .nm-model-band-stripe-container .nm-model-band-stripe-models-wrap,
.nm-model-band .nm-model-band-tab-nav-types-input:checked ~ .nm-model-band-stripe-container .nm-model-band-stripe-types-wrap {
	display: block;
}

.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe {
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: none;
	overflow-x: scroll;
	overflow-y: hidden;
}

.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe::-webkit-scrollbar {
	display: none;
}

.nm-model-band .nm-model-band-container ul {
	display: none;
}

.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe > ul {
	display: flex;
	flex-wrap: nowrap;
	padding-bottom: calc(25px + var(--space-m));
}

.nm-model-band .nm-model-band-stripe-navigation-item {
	background: #fff;
	display: block;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 54px;
}

.nm-model-band .nm-model-band-stripe-navigation-item-inner {
	border-right: 1px solid var(--color-grey-30);
	cursor: pointer;
	height: var(--car-height);
	position: relative;
	width: 100%;
}

.nm-model-band-stripe-navigation-item.nm-model-band-stripe-next {
	left: auto;
	right: 0;
}

.nm-j-model-band-hide-prev-arrow .nm-model-band-stripe-navigation-item.nm-model-band-stripe-prev,
.nm-j-model-band-hide-next-arrow .nm-model-band-stripe-navigation-item.nm-model-band-stripe-next {
	display: none;
}

.nm-model-band-stripe-navigation-item.nm-model-band-stripe-next .nm-model-band-stripe-navigation-item-inner {
	border-left: 1px solid var(--color-grey-30);
	border-right: none;
}

.nm-model-band-stripe-navigation-item svg {
	height: var(--icon-size-large);
	left: calc(50% - var(--icon-size-large) / 2);
	position: absolute;
	top: calc(50% - var(--icon-size-large) / 2);
	width: var(--icon-size-large);
}

.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe > ul li.nm-model-band-stripe-item {
	animation: nm-model-band-strip-animate-in .3s ease-out forwards;
	box-sizing: border-box;
	opacity: 0;
	position: relative;
	transform: translate3d(-100%, 0 0 );
	width: var(--car-width);
}

@keyframes nm-model-band-strip-animate-in {
	from {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
}

.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe > ul li.nm-model-band-stripe-item label {
	cursor: pointer;
	width: 100%;
}

.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe-models .nm-model-band-stripe-models-default,
.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe-types .nm-model-band-stripe-types-default {
	bottom: 0;
	display: none;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.nm-model-band .nm-model-band-container .nm-model-band-container-headline .nm-model-band-container-headline-text {
	display: block;
	font-weight: var(--font-weight-bold);
	width: 100%;
}

.nm-model-band-container-item-eec-info-text,
.nm-model-band-eec-explanation-text {
	display: block;
	padding-left: var(--space-xl);
	padding-top: 4px;
}

.nm-model-band-container-item-eec-info-text--eec {
	padding-left: 0;
}

.nm-model-band-container-item-eec-info-label {
	margin-top: var(--space-xs);
	position: relative;
}

.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe > ul li.nm-model-band-stripe-item label span {
	display: block;
	text-align: center;
}

.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe > ul li.nm-model-band-stripe-item .nm-model-band-stripe-img {
	margin: 0 auto;
}

.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe > ul li.nm-model-band-stripe-item .nm-model-band-stripe-img-active {
	display: none;
}

.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe > ul li.nm-model-band-stripe-item .nm-model-band-stripe-img,
.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe > ul li.nm-model-band-stripe-item .nm-model-band-stripe-img-active {
	height: var(--car-height);
	width: var(--car-width);
}

.nm-model-band .nm-model-band-tab-navigation .nm-model-band-models-label,
.nm-model-band .nm-model-band-tab-navigation .nm-model-band-types-label {
	opacity: .6;
}

.nm-model-band .nm-model-band-tab-nav-models-input:checked ~ .nm-model-band-tab-navigation .nm-model-band-models-label,
.nm-model-band .nm-model-band-tab-nav-types-input:checked ~ .nm-model-band-tab-navigation .nm-model-band-types-label {
	border-bottom: 1px solid #000;
	cursor: default;
	opacity: 1;
	pointer-events: none;
}

.nm-model-band .nm-model-band-container {
	background-color: #fff;
	display: block;
	height: 0;
	margin-top: -25px;
	overflow: hidden;
	position: relative;
	transition: height .3s ease-out;
}

.nm-model-band .nm-model-band-container::before {
	background-color: var(--color-grey-30);
	content: "";
	display: block;
	height: 1px;
	left: 0;
	position: absolute;
	top: 1px;
	width: 100%;
}

.nm-model-band .nm-model-band-container .nm-model-band-container-close-button {
	cursor: pointer;
	display: block;
	height: 48px;
	margin: 0 auto;
	position: absolute;
	right: var(--space-column);
	top: var(--space-s);
	width: 48px;
}

.nm-model-band .nm-model-band-container .audi-cancel-large-filled {
	color: var(--color-black);
	fill: currentColor;
	height: 48px;
	width: 48px;
}

.nm-model-band .nm-model-band-container .nm-model-band-container-close-button:hover .audi-cancel-large-filled {
	color: var(--color-grey-30);
}

.nm-model-band .nm-model-band-tab-nav-models-input:checked ~ .nm-model-band-container .nm-model-band-container-models,
.nm-model-band .nm-model-band-tab-nav-types-input:checked ~ .nm-model-band-container .nm-model-band-container-types {
	display: block;
}

.nm-model-band .nm-model-band-container .nm-model-band-container-item {
	height: 0;
	overflow: hidden;
}

.nm-model-band .nm-model-band-container .nm-model-band-container-item .nm-model-band-container-item-inner {
	position: relative;
}

.nm-model-band .nm-model-band-container .nm-model-band-container-item .nm-model-band-container-item-inner .nm-model-band-container-item-list {
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	padding: 0 var(--space-column);
	width: 100%;
}

.nm-model-band .nm-model-band-container .nm-model-band-container-item-list li {
	margin-bottom: var(--space-xl);
	width: var(--car-width);
}

.nm-model-band .nm-model-band-container .nm-model-band-container-headline {
	box-sizing: border-box;
	min-height: 72px;
	padding-left: var(--space-column);
	padding-right: 64px;
	padding-top: var(--space-m);
	width: 100%;
}

.nm-model-band .nm-model-band-container .nm-model-band-container-item-img {
	display: block;
	height: auto;
	transform: scale(.95);
	transition: transform .3s;
	width: var(--car-width);
}

.nm-model-band .nm-model-band-container .nm-model-band-container-item-list li:hover .nm-model-band-container-item-img {
	transform: scale(1);
}

.nm-model-band .nm-model-band-container .nm-model-band-container-item-eec-info {
	display: flex;
	justify-content: center;
	padding: 0 var(--space-s);
}

.nm-model-band .nm-model-band-container .nm-model-band-container-item-label {
	text-align: center;
	width: 100%;
}

.nm-model-band .nm-model-band-container .nm-icon-consumption,
.nm-model-band .nm-model-band-container .nm-icon-emission {
	display: inline-block;
	fill: #000;
	height: 24px;
	left: 0;
	position: absolute;
	top: 0px;
	vertical-align: bottom;
	width: 24px;
}

.nm-model-band .nm-model-band-container .nm-model-band-eec-explanation {
	border-top: 1px solid var(--color-grey-30);
	box-sizing: border-box;
	opacity: 0;
	padding: var(--space-s) var(--space-column);
	transition: opacity .3s 0s;
	width: 100%;
}

.nm-model-band .nm-model-band-container.nm-j-model-band-container-is-open .nm-model-band-eec-explanation {
	opacity: 1;
	transition-delay: .3s;
}

.nm-model-band .nm-model-band-container .nm-model-band-eec-explanation .nm-model-band-eec-explanation-consumption,
.nm-model-band .nm-model-band-container .nm-model-band-eec-explanation .nm-model-band-eec-explanation-emission {
	margin-bottom: var(--space-xs);
	position: relative;
}

.nm-model-band .nm-model-band-container .nm-model-band-eec-explanation .nm-model-band-eec-explanation-consumption {
	margin-bottom: var(--space-s);
}

@media all and (min-width: 768px) {
	.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe > ul li.nm-model-band-stripe-item:hover {
		cursor: pointer;
	}

	.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe > ul li.nm-model-band-stripe-item label .nm-model-band-stripe-img {
		transform: scale(.95);
		transition: transform .3s;
	}

	.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe > ul li.nm-model-band-stripe-item:hover .nm-model-band-stripe-img {
		padding: 0;
		transform: scale(1);
	}

	.nm-model-band .nm-model-band-stripe-container .nm-model-band-stripe > ul li.nm-model-band-stripe-item:hover span,
	.nm-model-band .nm-model-band-container .nm-model-band-container-item-list li:hover .nm-model-band-container-item-label {
		color: var(--color-grey-70);
		font-weight: var(--font-weight-bold);
		text-decoration: underline;
	}

	.nm-model-band .nm-model-band-container .nm-model-band-container-close-button {
		left: 0;
		position: absolute;
		right: 0;
		top: var(--space-s);
	}

	.nm-model-band .nm-model-band-container .nm-model-band-container-item-visible {
		padding-top: 0 !important;
	}

	.nm-model-band .nm-model-band-container .nm-model-band-container-headline {
		min-height: 0;
		padding-left: var(--space-column);
		padding-right: var(--space-column);
		padding-top: 72px;
		text-align: center;
	}

	.nm-model-band .nm-model-band-container .nm-model-band-eec-explanation .nm-model-band-eec-explanation-consumption,
	.nm-model-band .nm-model-band-container .nm-model-band-eec-explanation .nm-model-band-eec-explanation-emission {
		display: inline-block;
	}

	.nm-model-band .nm-model-band-container .nm-model-band-eec-explanation .nm-model-band-eec-explanation-consumption {
		margin-bottom: var(--space-xs);
		padding-right: var(--space-s);
	}
}
